import { undoable, withBi, getValidCollectionId } from '../utils'
import { EVENTS } from '../../../constants/bi'
import { ComponentConnection, ComponentRef, Message, Position, Product, Plugin } from '../api-types'
import CoreApi from '../core-api'
import { SecondsToResetDefaults, SuccessActionTypes } from '../../../constants/success-settings'
import {
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_FORM,
  ROLE_MESSAGE,
  ROLE_SUBMIT_BUTTON,
} from '../../../constants/roles'
import {
  createHiddenMessage,
  createSubmitButton,
  getExtraMessageText,
} from '../services/form-service'
import { LinkTypes, mediaTypes } from '../../../panels/settings-panel/constants'
import { SUBMIT } from './consts/heights'
import { EMPTY_EMAIL_ID, innerText, isEmptyEmailId } from '../../../utils/utils'
import * as _ from 'lodash'
import { ButtonType, MessageType } from '../../../constants/field-types'
import {
  DEFAULT_EXTERNAL_LINK_OBJECT,
  DEFAULT_LINK_OBJECT,
  DEFAULT_UPLOAD_OBJECT,
  LinkPanelTypesToActionTypes,
  MediaManagerUploadedObjectResponse,
  UploadStatuses,
  VISIBLE_LINK_PANEL_SECTIONS,
  VISIBLE_LINK_PANEL_SECTIONS_ADI,
} from './consts/links-settings'
import RemoteApi from '../../../panels/commons/remote-api'
import { FormPreset } from '../../../constants/form-types'
import { PremiumRestriction } from '../../../constants/premium'
import { MissingField } from '../../../panels/settings-panel/components/crucial-elements/crucial-elements'
import { LAST_FIELD_PADDING } from '../layout-panel/constants/layout-settings'
import {
  getPlugins,
  convertPluginsToFormsPlugins,
  updatePlugin,
  findPlugin,
  removePlugin,
  isNativeForm,
} from '../plugins/utils'
import { OwnSettingsPanelProps } from '../../../panels/form-settings-panel/components/form-settings-panel'
import translations from '../../../utils/translations'
import { PAYMENT_STATUS } from '../../../panels/form-settings-panel/components/payment/constants'
import { TABS } from '../../../panels/form-settings-panel/constants'
import { FormPlugin } from '../../../constants/plugins'
import Experiments from '@wix/wix-experiments'

export interface InitialSettingsPanelData {
  message?: string
  links?: any
  email?: string
  secondEmail?: string
  missingFields?: MissingField[]
  otherFormsNames?: string[]
  isCollectionExists?: boolean
  isRegistrationForm?: boolean
  restrictions?: PremiumRestriction
  productName?: string
  productPrice?: string
}

export interface InitialSubmitPanelData {
  message?: string
  links?: any
  missingFields?: MissingField[]
  isRegistrationForm?: boolean
  restrictions?: PremiumRestriction
}

interface GetEmailsResponse {
  email: {
    email: string
    emailId: string
  }
  secondEmail: {
    email: string
    emailId: string
  }
}

export const SETTINGS_API_NAME = 'settings'

const apiPath = funcName => `${SETTINGS_API_NAME}.${funcName}`

export default class SettingsApi {
  private biLogger: any
  private experiments: Experiments
  private boundEditorSDK: any
  private coreApi: CoreApi
  private remoteApi: RemoteApi
  private editorSDK: any

  constructor(boundEditorSDK, editorSDK, coreApi: CoreApi, remoteApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.editorSDK = editorSDK
    this.experiments = experiments
  }

  public async loadInitialSubmitPanelData(
    componentRef: ComponentRef
  ): Promise<InitialSubmitPanelData> {
    const formComponentRef = await this.coreApi.findComponentByRole(componentRef, ROLE_FORM)
    const formComponentConnection = await this.coreApi.getComponentConnection(formComponentRef)
    return Promise.all([
      this.getMessage(formComponentRef),
      this.getMessage(formComponentRef, ROLE_DOWNLOAD_MESSAGE),
      this.getSubmitOptionsData(formComponentRef, formComponentConnection),
      this.getCrucialElements(formComponentRef, formComponentConnection),
      this.coreApi.isRegistrationForm(formComponentRef),
      this.coreApi.premium.getPremiumRestrictions(),
      this.coreApi.getButtonLabel(componentRef),
    ]).then(
      ([
        successMessage,
        downloadMessage,
        links,
        missingFields,
        isRegistrationForm,
        { restrictions },
        buttonLabel,
      ]) => ({
        successMessage: successMessage.text,
        downloadMessage: downloadMessage.text,
        messagePosition: successMessage.position || downloadMessage.position,
        links,
        missingFields,
        isRegistrationForm,
        restrictions,
        buttonLabel,
        formComponentRef,
        formComponentConnection,
        submitComponentRef: componentRef,
      })
    )
  }

  public async loadInitialPanelData(
    componentRef: ComponentRef,
    componentConnection: ComponentConnection
  ): Promise<InitialSettingsPanelData> {
    return Promise.all([
      this.getMessage(componentRef),
      this.getMessage(componentRef, ROLE_DOWNLOAD_MESSAGE),
      this.getSubmitOptionsData(componentRef, componentConnection),
      this.getEmails(componentRef, componentConnection),
      this.getCrucialElements(componentRef, componentConnection),
      this.getOtherFormsNames(componentRef),
      this.coreApi.isCollectionExists(componentRef, componentConnection),
      this.coreApi.isRegistrationForm(componentRef),
      this.coreApi.premium.getPremiumRestrictions(),
      this.getSubmitComponentRef(componentRef),
      this.coreApi.getOwnerEmail(),
    ]).then(
      ([
        successMessage,
        downloadMessage,
        links,
        { email, secondEmail },
        missingFields,
        otherFormsNames,
        isCollectionExists,
        isRegistrationForm,
        { restrictions },
        submitComponentRef,
        ownerEmailObj,
      ]) => {
        const ownerEmail = _.get(ownerEmailObj, 'email', '')
        const firstEmail = _.get(email, 'email', ownerEmail)

        return {
          successMessage: successMessage.text,
          downloadMessage: downloadMessage.text,
          messagePosition: successMessage.position || downloadMessage.position,
          links,
          email: firstEmail,
          secondEmail: _.get(secondEmail, 'email', ''),
          missingFields,
          otherFormsNames,
          isCollectionExists,
          isRegistrationForm,
          restrictions,
          plugins: convertPluginsToFormsPlugins(getPlugins(componentConnection)),
          formComponentRef: componentRef,
          formComponentConnection: componentConnection,
          submitComponentRef,
          ownerEmail,
        }
      }
    )
  }

  public async loadInitialSettingsPanelData({
    componentRef,
    componentConnection,
    displayedTab,
  }: {
    componentRef: ComponentRef
    componentConnection: ComponentConnection
    displayedTab: any
  }): Promise<Partial<OwnSettingsPanelProps>> {
    return Promise.all([
      this.getOtherFormsNames(componentRef),
      this.getMessage(componentRef),
      this.getMessage(componentRef, ROLE_DOWNLOAD_MESSAGE),
      this.getSubmitOptionsData(componentRef, componentConnection),
      this.getEmails(componentRef, componentConnection),
      this.coreApi.getOwnerEmail(),
      this.getCrucialElements(componentRef, componentConnection),
      this.coreApi.premium.getPremiumRestrictions(),
      this.getSubmitComponentRef(componentRef),
      this.coreApi.isCollectionExists(componentRef, componentConnection),
      this.getLabels(), // TODO: Move all secondary calls to componentWillMount on form-settings component
      this.coreApi.isWixChatInstalled(),
      this.boundEditorSDK.info.getCurrency(),
      this.determinePaymentStatus(componentRef),
      this.coreApi.addForm.isContainerInAppWidget(componentRef),
      this.boundEditorSDK.environment.getLocale()
    ]).then(
      ([
         otherFormsNames,
         successMessage,
         downloadMessage,
         links,
         { email, secondEmail },
         ownerEmailObj,
         missingFields,
         { restrictions },
         submitComponentRef,
         isCollectionExists,
         labels,
         isWixChatInstalled,
         currency,
         paymentStatus,
         isContainerInAppWidget,
         locale
       ]) => {
        const ownerEmail = _.get(ownerEmailObj, 'email', '')
        const plugins = getPlugins(componentConnection)
        const paymentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)
        const items = _.get(paymentPlugin, 'payload.items')
        const products = _.map(items, (item, id) => ({ id, ...item }))
        const isRegistrationForm = !!findPlugin(plugins,FormPlugin.REGISTRATION_FORM)

        const showPaymentTabInFormBuilderPlugin =
          this.experiments.enabled('specs.cx.FormBuilderShowPaymentTab') &&
          (isNativeForm(plugins) || !!findPlugin(plugins, FormPlugin.PAYMENT_FORM))

        const showPaymentTabInGetSubscribersPlugin =
          this.experiments.enabled('specs.cx.FormBuilderShowPaymentTabInGetSubscribers') &&
          !!findPlugin(plugins, FormPlugin.GET_SUBSCRIBERS)

        const showPaymentTabInRegistrationFormPlugin =
          this.experiments.enabled('specs.cx.FormBuilderShowPaymentTabInRegistrationForm') &&
          !!findPlugin(plugins, FormPlugin.REGISTRATION_FORM)

        const showPaymentTab =
          showPaymentTabInFormBuilderPlugin ||
          showPaymentTabInGetSubscribersPlugin ||
          showPaymentTabInRegistrationFormPlugin

        const settingsPanelProps: Partial<OwnSettingsPanelProps> = {
          isCollectionExists,
          preset: componentConnection.config.preset,
          plugins: convertPluginsToFormsPlugins(plugins),
          email: _.get(email, 'email', ownerEmail),
          secondEmail: _.get(secondEmail, 'email', ''),
          labels,
          formLabelId: componentConnection.config.formLabelId,
          selectedLabels: componentConnection.config.labels || [],
          formName: componentConnection.config.formName,
          lastValidFormName: componentConnection.config.formName,
          otherFormsNames,
          successActionType:
            componentConnection.config.successActionType || SuccessActionTypes.SHOW_MESSAGE,
          secondsToResetForm:
            componentConnection.config.secondsToResetForm || SecondsToResetDefaults.MIN,
          links,
          missingFields,
          successMessage:
            _.get(successMessage, 'text') ||
            (isRegistrationForm
              ? translations.t('settings.errorMessage.registrationForm')
              : translations.t('settings.successMessage.default')),
          downloadMessage:
            _.get(downloadMessage, 'text') || translations.t('settings.successMessage.download'),
          restrictions,
          formComponentRef: componentRef,
          submitComponentRef: submitComponentRef,
          messagePosition: successMessage.position || downloadMessage.position,
          isWixChatInstalled,
          productId: _.get(products, '[0].id'),
          productName: _.get(products, '[0].name'),
          productPrice: _.get(products, '[0].price'),
          currency,
          paymentStatus: paymentStatus || PAYMENT_STATUS.GET_STARTED,
          selectedTab: displayedTab || TABS.MAIN,
          isRegistrationForm: isRegistrationForm,
          isContainerInAppWidget,
          showPaymentTab,
          isGetSubscribersForm: !!findPlugin(plugins,FormPlugin.GET_SUBSCRIBERS),
          locale
        }

        return settingsPanelProps
      }
    )
  }

  @undoable()
  @withBi({
    startEvid: EVENTS.PANELS.settingsPanel.CREATE_SUBMISSIONS_TABLE,
    endEvid: EVENTS.PANELS.settingsPanel.SUBMISSIONS_TABLE_CREATED_SUCCESSFULLY,
  })
  public async createCollection(componentRef: ComponentRef, _biData = {}): Promise<string> {
    return this.coreApi.createCollection(componentRef)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public setComponentConnection(
    connectToRef: ComponentRef,
    connectionConfig,
    deepMerge = true,
    _biData = {}
  ) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig, deepMerge)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setLabels(componentRef: ComponentRef, labels: string[], _biData = {}) {
    return this.setComponentConnection(componentRef, { labels })
  }

  public async saveProduct(componentRef: ComponentRef, currency: string, product: Product) {
    const componentConnection = await this.coreApi.getComponentConnection(componentRef)
    const plugins: Plugin[] = _.get(componentConnection, 'config.plugins')

    let paymentPlugin: Plugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)

    const initPaymentPlugin = () => {
      paymentPlugin = {
        id: FormPlugin.PAYMENT_FORM,
        payload: {
          currency,
          items: {
            [product.id]: {
              name: product.name,
              price: product.price,
              quantity: product.quantity || 1,
            },
          },
        },
      }
    }

    const updatePaymentPlugin = () => {
      paymentPlugin.payload.currency = currency
      paymentPlugin.payload.items[product.id] = {
        name: product.name,
        price: product.price,
        quantity: product.quantity || 1,
      }
    }

    if (paymentPlugin && paymentPlugin.payload) {
      updatePaymentPlugin()
    } else {
      const collectionId = getValidCollectionId(componentRef.id, _.get(componentConnection, 'config.collectionId'))
      if (collectionId) {
        await this.coreApi.collectionsApi.addPaymentField(collectionId)
      }
      initPaymentPlugin()
    }

    const updatedPlugins = updatePlugin(plugins, paymentPlugin)

    return this.setComponentConnection(
      componentRef,
      {
        plugins: updatedPlugins,
      },
      false
    )
  }

  public async removePlugin(componentRef: ComponentRef, pluginId: FormPlugin) {
    const componentConnection = await this.coreApi.getComponentConnection(componentRef)
    const plugins = getPlugins(componentConnection)

    return this.setComponentConnection(
      componentRef,
      {
        plugins: removePlugin(plugins, pluginId),
      },
      false
    )
  }

  public async determinePaymentStatus(componentRef: ComponentRef): Promise<PAYMENT_STATUS> {
    const [PremiumRestrictions, hasConnectedPayment, componentConnection] = await Promise.all([
      this.coreApi.premium.getPremiumRestrictions(),
      this.coreApi.hasConnectedPayment(),
      this.coreApi.getComponentConnection(componentRef),
    ])
    const plugins = getPlugins(componentConnection)
    const paymentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)

    if (!paymentPlugin || !paymentPlugin.payload) {
      return PAYMENT_STATUS.GET_STARTED
    }

    if (PremiumRestrictions.restrictions.allowedPlugins[FormPlugin.PAYMENT_FORM]) {
      return hasConnectedPayment ? PAYMENT_STATUS.CONNECTED : PAYMENT_STATUS.MISSING_PAYMENT_METHOD
    } else {
      return PAYMENT_STATUS.UPGRADE_TO_ASCEND
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.SUCCESS_ACTION_TYPE_SELECTED })
  public async setSuccessActionTypeADI(
    connectToRef: ComponentRef,
    successActionType: SuccessActionTypes,
    successLinkValue,
    showTitles,
    newSuccessMessage = '',
    _biData = {}
  ) {
    await this._setSuccessActionType(connectToRef, successActionType, successLinkValue, {
      newMessage: newSuccessMessage,
    })
    await this.coreApi.layout.updateFieldsLayoutADI(connectToRef, { showTitles })
  }

  private async _setSuccessActionType(
    componentRef: ComponentRef,
    successActionType: SuccessActionTypes,
    successLinkValue,
    { newMessage, messagePosition }: { newMessage?: string; messagePosition?: Position } = {}
  ) {
    const {
      config: { successActionType: oldSuccessActionType },
    } = await this.coreApi.getComponentConnection(componentRef)
    await this.coreApi.setComponentConnection(
      componentRef,
      { successActionType, successLinkValue },
      false
    )

    switch (oldSuccessActionType) {
      case SuccessActionTypes.SHOW_MESSAGE:
        await this._removeMessage(componentRef)
        break
      case SuccessActionTypes.DOWNLOAD_DOCUMENT:
        await this._removeMessage(componentRef, ROLE_DOWNLOAD_MESSAGE)
        break
    }
    switch (successActionType) {
      case SuccessActionTypes.SHOW_MESSAGE:
        await this._addHiddenMessage(componentRef, { newMessage, messagePosition })
        break
      case SuccessActionTypes.DOWNLOAD_DOCUMENT:
        await this._addDownloadDocumentMessage(componentRef, { newMessage, messagePosition })
    }
  }

  @undoable()
  public async setSuccessActionType(
    connectToRef: ComponentRef,
    successActionType: SuccessActionTypes,
    successLinkValue,
    { newMessage, messagePosition }: { newMessage?: string; messagePosition?: Position } = {}
  ) {
    return this._setSuccessActionType(connectToRef, successActionType, successLinkValue, {
      newMessage,
      messagePosition,
    })
  }

  @undoable()
  public addHiddenMessage(componentRef: ComponentRef) {
    return this._addHiddenMessage(componentRef)
  }

  @undoable()
  public addDownloadMessage(componentRef: ComponentRef, newMessage: string) {
    return this._addDownloadDocumentMessage(componentRef, { newMessage })
  }

  private async _removeMessage(componentRef: ComponentRef, role: string = ROLE_MESSAGE) {
    const get = async () => {
      const messageRef = await this.coreApi.findComponentByRole(componentRef, role)
      const messageLayout = await this.boundEditorSDK.components.layout.get({
        componentRef: messageRef,
      })
      return { messageRef, messageLayout }
    }
    const { messageRef, messageLayout } = await get()
    if (!messageLayout) {
      return
    }

    return this.coreApi.removeComponentRef(messageRef)
  }

  @undoable()
  public async handleSuccessLinkPanel(componentRef: ComponentRef, isADI = false) {
    const {
      config: { successLinkValue, successActionType },
    } = await this.coreApi.getComponentConnection(componentRef)

    let linkObject
    try {
      linkObject = await this.boundEditorSDK.editor.openLinkPanel({
        value: successLinkValue,
        visibleSections: isADI ? VISIBLE_LINK_PANEL_SECTIONS_ADI : VISIBLE_LINK_PANEL_SECTIONS,
      })
    } catch (e) {
      return {
        chosenLinkType: null,
        successLinkText: null,
        linkObject: null,
      }
    }

    const successLinkText = await this.boundEditorSDK.editor.utils.getLinkAsString({
      link: linkObject,
    })

    const chosenLinkType =
      linkObject && linkObject.type
        ? LinkPanelTypesToActionTypes[linkObject.type]
        : successActionType
    await this.setSuccessActionType(componentRef, chosenLinkType, linkObject)

    const successLinkType = await this._getLinkType(linkObject)
    const successLinkSubType = this._getLinkSubType(successLinkText, successLinkType, linkObject)

    return {
      chosenLinkType: chosenLinkType,
      successLinkText,
      linkObject,
      successLinkType,
      successLinkSubType,
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.CLICK_UPLOAD_BUTTON })
  public async updateDownloadSection(componentRef: ComponentRef, _biData) {
    let {
      config: { successLinkValue },
    } = await this.coreApi.getComponentConnection(componentRef)

    try {
      const uploadedObject: Array<
        MediaManagerUploadedObjectResponse
      > = await this.boundEditorSDK.editor.openMediaPanel({
        mediaType: mediaTypes.DOCUMENT,
        isMultiSelect: false,
      })

      if (uploadedObject) {
        successLinkValue.docId = _.head(uploadedObject).uri
        successLinkValue.name = _.head(uploadedObject).title
        successLinkValue.status = UploadStatuses.UPLOAD_SUCCESS
      }
    } catch (error) {
      successLinkValue.status = UploadStatuses.UPLOAD_FAILED
    }
    await this.setComponentConnection(
      componentRef,
      {
        successLinkValue: successLinkValue,
      },
      false
    )
    return successLinkValue
  }

  public async getSubmitOptionsData(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection
  ) {
    const {
      config: { successActionType, successLinkValue },
    } = componentConnection || (await this.coreApi.getComponentConnection(componentRef))

    const getText = linkObj => this.boundEditorSDK.editor.utils.getLinkAsString({ link: linkObj })

    const links = {
      [SuccessActionTypes.LINK]: {
        text: await getText(DEFAULT_LINK_OBJECT),
        object: DEFAULT_LINK_OBJECT,
      },
      [SuccessActionTypes.EXTERNAL_LINK]: {
        text: await getText(DEFAULT_EXTERNAL_LINK_OBJECT),
        object: DEFAULT_EXTERNAL_LINK_OBJECT,
      },
      [SuccessActionTypes.DOWNLOAD_DOCUMENT]: {
        object: DEFAULT_UPLOAD_OBJECT,
      },
    }

    if (successActionType !== SuccessActionTypes.SHOW_MESSAGE) {
      links[successActionType] = {
        text: await getText(successLinkValue),
        object: successLinkValue,
      }
    }
    return links
  }

  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setEmail(componentRef, emailIdKey, email, _biData = {}) {
    let emailId

    if (!_.isEmpty(email)) {
      const { id } = await this.remoteApi.insertEmail(email)
      emailId = id
    } else {
      emailId = EMPTY_EMAIL_ID
    }

    return this.setComponentConnection(componentRef, { [emailIdKey]: emailId })
  }

  @withBi({
    startEvid: EVENTS.PANELS.settingsPanel.SUCCESS_ACTION_TYPE_SELECTED,
    endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED,
  })
  public async updateMessage(
    componentRef: ComponentRef,
    { newMessage, role = ROLE_MESSAGE },
    _biData = {}
  ) {
    const messageRef = await this.coreApi.findComponentByRole(componentRef, role)
    const data = await this.boundEditorSDK.components.data.get({ componentRef: messageRef })

    return this.boundEditorSDK.components.data.update({
      componentRef: messageRef,
      data: getExtraMessageText({ data, newMessage }),
    })
  }

  public async getSubmitButtonLabel(componentRef: ComponentRef) {
    const submitButtonRef = await this.coreApi.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON)
    return this.coreApi.getButtonLabel(submitButtonRef)
  }

  public async getSubmitComponentRef(componentRef: ComponentRef) {
    const submitRef = await this.coreApi.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON)
    return submitRef
  }

  @undoable()
  public async updateSubmitButtonLabel(componentRef: ComponentRef, newLabel: string) {
    const submitButtonRef = await this.coreApi.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON)
    return this.coreApi.updateButtonLabel(submitButtonRef, newLabel)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.SECONDS_TO_RESET_UPDATED })
  public setComponentConnectionResetUpdated(
    connectToRef: ComponentRef,
    connectionConfig,
    _biData = {}
  ) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  public getTags() {
    return this.remoteApi.getTags()
  }

  public getLabels() {
    return this.remoteApi.getLabels()
  }

  @undoable()
  public async addSubmitButton(componentRef: ComponentRef) {
    const updateMessageLocation = async () => {
      const messages = await this.coreApi.layout.getChildrenLayouts(componentRef, ROLE_MESSAGE)
      const message: any = messages[0]
      if (!message) {
        return null
      }
      return this.boundEditorSDK.components.layout.update({
        componentRef: message.componentRef,
        layout: { y: message.y + SUBMIT.MESSAGE },
      })
    }

    const createButton = async (preset: FormPreset, locale, layout) => {
      const buttonType = (await this.coreApi.isRegistrationForm(componentRef))
        ? ButtonType.SIGNUP
        : ButtonType.SUBMIT

      return createSubmitButton(
        { layout, buttonType },
        {
          presetKey: preset,
          locale,
          onFailedPresetCallback: reason => this.coreApi.logFetchPresetsFailed(null, reason),
        }
      )
    }
    this._addCruicialElement(componentRef, createButton)
    return updateMessageLocation()
  }

  public async getMessage(
    componentRef: ComponentRef,
    role: string = ROLE_MESSAGE
  ): Promise<Message> {
    const messageRef = await this.coreApi.findComponentByRole(componentRef, role)
    if (!messageRef) {
      return { text: '' }
    }
    const [{ data, layout }] = await this.boundEditorSDK.components.get({
      componentRefs: messageRef,
      properties: ['data', 'layout'],
    })
    const { x, y } = layout
    return { text: _.unescape(innerText(data.text)), position: { x, y } }
  }

  public async getEmails(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection
  ): Promise<GetEmailsResponse> {
    const {
      config: { emailId, secondEmailId },
    } = componentConnection || (await this.coreApi.getComponentConnection(componentRef))

    // both not empty - use single api call to fetch both
    if (!isEmptyEmailId(emailId) && !isEmptyEmailId(secondEmailId)) {
      return this.remoteApi.getEmailsById(emailId, secondEmailId)
    }

    // both empty - return empty valid object
    if (isEmptyEmailId(emailId) && isEmptyEmailId(secondEmailId)) {
      return {
        email: {
          emailId,
          email: '',
        },
        secondEmail: {
          emailId: secondEmailId,
          email: '',
        },
      }
    }

    // one of them not empty - use single api call to fetch email
    if (isEmptyEmailId(secondEmailId)) {
      return {
        email: await this.remoteApi.getEmailById(emailId),
        secondEmail: {
          emailId: secondEmailId,
          email: '',
        },
      }
    } else {
      return {
        email: {
          emailId,
          email: '',
        },
        secondEmail: await this.remoteApi.getEmailById(emailId),
      }
    }
  }

  public async getCrucialElements(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection
  ) {
    let connection =
      componentConnection || (await this.coreApi.getComponentConnection(componentRef))

    const { controllerRef, config } = connection

    const plugins = _.get(config, 'plugins')
    const successActionType = _.get(config, 'successActionType')

    const pluginApi = this.coreApi.plugins.withPlugins(plugins)

    if (pluginApi) {
      //TODO: Thing about different solution
      const funcName = 'getCrucialElements'

      if (pluginApi.supportApi(apiPath(funcName))) {
        return pluginApi.callApi(apiPath(funcName), componentRef, connection)
      }
    }

    const isMessageFieldMissingPromise =
      successActionType === SuccessActionTypes.SHOW_MESSAGE
        ? this.coreApi.isFieldMissingByRole(componentRef, ROLE_MESSAGE)
        : Promise.resolve(null)
    const isDownloadMessageFieldMissingPromise =
      successActionType === SuccessActionTypes.DOWNLOAD_DOCUMENT
        ? this.coreApi.isFieldMissingByRole(componentRef, ROLE_DOWNLOAD_MESSAGE)
        : Promise.resolve(null)

    const missingFields = await Promise.all([
      isMessageFieldMissingPromise,
      isDownloadMessageFieldMissingPromise,
      this.coreApi.isFieldMissingByRole(componentRef, ROLE_SUBMIT_BUTTON),
      this.coreApi.isEmailFieldMissing(controllerRef),
    ])

    return _.filter(missingFields)
  }

  public async getOtherFormsNames(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection
  ): Promise<string[]> {
    const { controllerRef } =
      componentConnection || (await this.coreApi.getComponentConnection(componentRef))
    const controllers = _.map(
      await this.boundEditorSDK.controllers.listAllControllers(),
      ({ controllerRef }) => controllerRef
    )
    return await Promise.all(
      _.map(_.pullAllBy(controllers, [controllerRef], 'id'), async formControllerRef => {
        const formRef = await this.coreApi.findConnectedComponent(formControllerRef, ROLE_FORM)
        if (!formRef) {
          return ''
        }
        const {
          config: { formName },
        } = await this.coreApi.getComponentConnection(formRef)
        return formName
      })
    )
  }

  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async updateFormName(
    connectToRef: ComponentRef,
    { formName }: { formName: string },
    _biData = {}
  ) {
    await this.coreApi.setComponentConnection(connectToRef, { formName })
    const {
      config: { formLabelId, labels },
    } = await this.coreApi.getComponentConnection(connectToRef)
    if (formLabelId && _.includes(labels, formLabelId)) {
      await this.remoteApi.updateTag(formLabelId, formName)
    }
  }

  private async _addCruicialElement(
    componentRef: ComponentRef,
    createElement: (preset: FormPreset, locale, layout, boxLayout) => any
  ) {
    const { controllerRef, config } = await this.coreApi.getComponentConnection(componentRef)
    const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef })
    const locale = await this.boundEditorSDK.info.getLanguage()
    const formBottomPosition = boxLayout.height
    const theme = _.get(config, 'theme')
    const preset = _.get(config, 'preset')

    const element = await createElement(
      preset,
      locale,
      {
        y: formBottomPosition,
      },
      boxLayout
    )
    const fieldLayout = element.data.layout

    const updateBoxLayout = async boxRef => {
      const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef: boxRef })

      if(!boxLayout.height) {
        return
      }

      return this.boundEditorSDK.components.layout.update({
        componentRef: boxRef,
        layout: { height: boxLayout.height + fieldLayout.height + LAST_FIELD_PADDING },
      })
    }

    const updateAncestorsBoxLayout = () =>
      this.boundEditorSDK.components
        .getAncestors({ componentRef })
        .then(ancestors => Promise.all(_.map(ancestors, updateBoxLayout)))

    if (fieldLayout.y == formBottomPosition) {
      if (await this.coreApi.isRegistrationForm(componentRef)) {
        await Promise.all([updateBoxLayout(componentRef), updateAncestorsBoxLayout()]).then(() =>
          this.coreApi.layout.centerComponentInsideLightbox(componentRef)
        )
      } else {
        await Promise.all([updateAncestorsBoxLayout(), updateBoxLayout(componentRef)])
      }
    }

    const { connectToRef } = await this.coreApi.addComponentAndConnect(
      element,
      controllerRef,
      componentRef
    )

    if (theme) {
      this.coreApi.style.updateComponentTheme(connectToRef, theme)
    }
  }

  private async _addHiddenMessage(
    componentRef: ComponentRef,
    { newMessage, messagePosition }: { newMessage?: string; messagePosition?: Position } = {}
  ) {
    const createMessage = async (preset: FormPreset, locale, layout, boxLayout) => {
      const hiddenMessageType = (await this.coreApi.isRegistrationForm(componentRef))
        ? MessageType.REGISTRATION
        : MessageType.SUCCESS

      return createHiddenMessage(
        {
          layout: _.merge({}, layout, messagePosition),
          hiddenMessageType,
          newMessage,
          formWidth: boxLayout.width,
        },
        {
          presetKey: preset,
          locale,
          onFailedPresetCallback: reason => this.coreApi.logFetchPresetsFailed(null, reason),
        }
      )
    }
    return this._addCruicialElement(componentRef, createMessage)
  }

  private async _addDownloadDocumentMessage(
    componentRef: ComponentRef,
    { newMessage, messagePosition }: { newMessage?: string; messagePosition?: Position } = {}
  ) {
    const createMessage = async (preset: FormPreset, locale, layout, boxLayout) => {
      newMessage = `<span style="text-decoration: underline">${newMessage}</span>`
      return createHiddenMessage(
        {
          layout: _.merge({}, layout, messagePosition),
          hiddenMessageType: MessageType.DOWNLOAD,
          newMessage,
          formWidth: boxLayout.width,
        },
        {
          presetKey: preset,
          locale,
          onFailedPresetCallback: reason => this.coreApi.logFetchPresetsFailed(null, reason),
        }
      )
    }
    return this._addCruicialElement(componentRef, createMessage)
  }

  private async _getLinkType(linkObject) {
    if (!_.get(linkObject, 'pageId')) {
      if (_.get(linkObject, 'url')) {
        return LinkTypes.EXTERNAL_LINK
      }
      return LinkTypes.NONE
    }

    const linkedPageRef = { type: 'DESKTOP', id: linkObject.pageId.substring(1) }
    const linkData = await this.boundEditorSDK.components.data.get({
      componentRef: linkedPageRef,
    })
    return _.get(linkData, 'isPopup') ? LinkTypes.LIGHTBOX : LinkTypes.PAGE
  }

  private _getLinkSubType(successLinkText, successLinkType: LinkTypes, linkObject) {
    switch (successLinkType) {
      case LinkTypes.PAGE:
        return successLinkText
      case LinkTypes.LIGHTBOX:
        return linkObject.pageId
      case LinkTypes.EXTERNAL_LINK:
        return linkObject.url
      default:
        return null
    }
  }
}
