import * as _ from 'lodash'
import { createBoxManifest } from './box-manifest'
import { createFieldsManifests } from './field-manifest'
import {
  FIELDS_ROLES,
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_FORM,
  ROLE_MESSAGE,
  ROLE_SUBMIT_BUTTON,
  ROLE_TITLE,
} from '../../../constants/roles'
import { createSubmitButtonManifest } from './submit-button-manifest'
import { createHiddenMessageManifest } from './hidden-message-manifest'
import { createTitleManifest } from './title-manifest'
import { AppStateBuilder, AppStateObject } from '../app-state/app-state-builder'
import { FormPlugin } from '../../../constants/plugins'
import { MessageType } from '../../../constants/field-types'

const ALWAYS_HIDE_CONTROLLER = 'NEVER'
const states = _.flatMap(
  [FormPlugin.FORM_BUILDER, FormPlugin.GET_SUBSCRIBERS, FormPlugin.REGISTRATION_FORM],
  plugin =>
    _.flatMap(
      [true, false],
      duplicatable =>
        new AppStateBuilder({
          duplicatable,
          plugins: [plugin],
        })
    )
).filter(x => x.toString())

export const getAppManifest = ({
                                 connectFieldGfpp,
                                 isTopPremium,
                                 showNewSettingsPanel,
                                 showNewRegistrationSettingsPanel,
                                 showGetSubscribersSettingsPanel,
                                 replaceManageFieldsWithAddFieldGffp,
                               }) => {
  const enhanceState = state => ({
    ...state,
    connectFieldGfpp,
    isTopPremium,
    showNewSettingsPanel,
    showNewRegistrationSettingsPanel,
    showGetSubscribersSettingsPanel,
    replaceManageFieldsWithAddFieldGffp
  })

  const createFieldManifests = state => {
    return FIELDS_ROLES.reduce(
      (res, fieldRole) => ({
        ...res,
        [fieldRole]: createFieldsManifests(state)[fieldRole],
      }),
      {}
    )
  }

  const titleManifest = createTitleManifest()
  const createManifest = (appState: AppStateObject) => {
    const state = enhanceState(appState)
    return {
      visibility: ALWAYS_HIDE_CONTROLLER,
      ...createBoxManifest(state),
      connections: {
        [ROLE_FORM]: createBoxManifest(state),
        [ROLE_SUBMIT_BUTTON]: createSubmitButtonManifest(state),
        [ROLE_MESSAGE]: createHiddenMessageManifest(state),
        [ROLE_DOWNLOAD_MESSAGE]: createHiddenMessageManifest(state, MessageType.DOWNLOAD),
        [ROLE_TITLE]: titleManifest,
        ...createFieldManifests(state),
      },
    }
  }

  return {
    controllersStageData: {
      singlePostController: {
        default: createManifest({}),
        ...states.reduce((agg, state) => {
          agg[state.toString()] = createManifest(state.get())
          return agg
        }, {}),
      },
    },
  }
}
